import {Container, Nav, Navbar} from "react-bootstrap";
import React from "react";

export default function Footer() {

    const year = new Date().getFullYear();

    return (
        <Container className="container-small py-5 text-center footer">
            &copy; 2014 - {year}, Areeb Majeed
        </Container>
    );

}