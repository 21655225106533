import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function Home() {

    return (
        <HelmetProvider>

            <Helmet>
                <title>Areeb Majeed</title>
                <meta name="description" content="Areeb Majeed is a backend developer and entrepreneur based in Australia. He specializes in PHP, Golang, and MySQL."/>
            </Helmet>

            <Container className={"container-small"}>

                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        hi there, how you doing? 🖐️
                    </h1>

                    <p>
                        I'm Areeb Majeed and welcome to my website.
                    </p>

                    <p>
                        I have been passionate about technology since I was a kid. I started coding when I was 14 and I've been hooked ever since. As a backend developer and co-founder of <a href={"https://maileroo.com/"} target={"_blank"} className={"special-item"}><img src={"assets/images/maileroo.webp"} className={"mr-2"} alt={"Maileroo Logo"}/> <span>Maileroo</span></a>, I bring around eight years of experience specializing in PHP and Golang. During my time at <a href={"https://www.rmit.edu.au/"} target={"_blank"} className={"special-item"}><img src={"assets/images/rmit.png"}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            className={"mr-2"}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            alt={"RMIT University Logo"}/> <span>RMIT University</span></a>,
                        I also picked up skills in JavaScript, Java, Python, and C#, giving me a versatile toolkit for tackling different challenges.
                    </p>

                    <p>
                        At work, you'll find me using tools like Ansible, Terraform, Redis, RabbitMQ, Laravel, and React. I'm particularly passionate about DevOps, cryptocurrencies, and cloud computing, and I'm always excited to explore new technologies.
                    </p>

                    <p>
                        Currently based in Australia, I'm really into the tech startup scene. When I'm not working, I love to play video games and tinker with my own side projects. Feel free to reach out and say hello!
                    </p>

                    <br/>

                    <p>
                        <a href={"/guestbook"} className={"link"}><i data-feather="arrow-up-right" className={"sm-icon"}></i> Say hello!</a>
                    </p>

                </div>

            </Container>

        </HelmetProvider>
    );

}