import React, {useEffect} from "react";
import {Button, Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import {Toaster, toast} from "react-hot-toast";

export default function Contact() {

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [token, setToken] = React.useState("");
    const [processing, setProcessing] = React.useState(false);
    const recaptchaRef = React.useRef();

    const handleSubmit = (event) => {

        setProcessing(true);

        fetch(`${process.env.REACT_APP_API_URL}contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message,
                token: token
            })
        })
        .then(response => response.json())
        .then(data => {

            if (data.success) {

                toast.success(data.message);

                setName("");
                setEmail("");
                setMessage("");
                setToken("");

            } else {

                toast.error(data.message);

            }

            setProcessing(false);
            recaptchaRef.current.reset();

        })
        .catch(error => {
            toast.error("An error occurred while sending your message. Please try again later.");
        });

    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Areeb Majeed | Contact</title>
                <meta name="description" content="Interested in working together? Get in touch with me."/>
            </Helmet>
            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        get in touch
                    </h1>

                    <p>
                        I would love to hear from you! Whether you have a question, a project you would like me to work on, or just want to say hi, feel free to send me a message using the form below.
                    </p>

                    <div className={"mb-3"}>
                        <label htmlFor={"name"} className={"form-label"}>Name</label>
                        <input type={"text"} className={"form-control"} id={"name"} required maxLength={64} onChange={(event) => setName(event.target.value)} value={name}/>
                    </div>

                    <div className={"mb-3"}>
                        <label htmlFor={"email"} className={"form-label"}>Email Address</label>
                        <input type={"email"} className={"form-control"} id={"email"} required maxLength={64} onChange={(event) => setEmail(event.target.value)} value={email}/>
                    </div>

                    <div className={"mb-3"}>
                        <label htmlFor={"message"} className={"form-label"}>Message</label>
                        <textarea className={"form-control"} id={"message"} required rows={7} maxLength={100000} onChange={(event) => setMessage(event.target.value)} value={message}></textarea>
                    </div>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(token) => setToken(token)}
                    />

                    <div className="d-flex justify-content-between mt-3">

                        <Button variant="primary" type="button" onClick={handleSubmit} disabled={processing}>
                            <i data-feather="edit-2" className="pen-icon"></i>
                            &nbsp;
                            Get in Touch
                        </Button>

                    </div>

                </div>
            </Container>
        </HelmetProvider>
    );

}